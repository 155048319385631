import { XccEnvironment } from '@xcc-client/services';

export const environment: XccEnvironment = {
  envMode: 'prod',
  isProduction: true,
  stripeApiPublicKey: 'pk_live_u7e2Vq3iNI27Xo6FjY5bDyRP00Vl3rjHi1',
  xccApiHost: 'https://checkout-api.ecom-prod.driversed.com',
  xgritApiHostUrl: 'https://prd.xgritapi.com/api/1',
  xgritAuthorization: 'Basic MmM5MjBlOGYxOTI0ZWVjNzgxZWRjMzE1ZjgxZTQzYTE6',
  xgritWebAppUrl: 'https://app.driversed.com/user/signin',
  brand: 'dec',
  domain: '.driversed.com',
  clientHost: 'https://driversed.com/',
  termsAndConditionsUrl: 'https://driversed.com/terms-and-conditions/',
  recaptchaSiteKey: '6LcnfWMjAAAAAI7wMlWYTqnWRTivwx-bmDsmIzQL',
  stripeBetas: 'elements_enable_deferred_intent_beta_1',
  stripeApiVersion: '""',
  addPurchaseQueryParam: true,
  ambassador: {
    uid: '88fe223d-6732-4f8a-80e7-e1049b8f9f8b',
    isRecordConversionEnabled: true,
  },
  sentryDsn: 'https://c4d2a06d2dc27210958097d0f5cd776e@o1007654.ingest.sentry.io/4506360985616384',
  passwordConfig: {
    passwordRegEx: '^.{6,}$',
    passwordNotMatchMessage: 'Your password must be at least 6 characters long',
    showPasswordStrength: true,
  },
  temporaryDownurl: 'course-temporarily-unavailable/',
};
